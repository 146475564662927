<h2 mat-dialog-title>Create package</h2>

<mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input type="text" matInput formControlName="name"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
                @for (item of ['START', 'RECOVER']; track $index) {
                    <mat-option [value]="item">{{ item }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Initial Payment</mat-label>
            <input
                    [mask]="'separator.' + decimals()"
                    [dropSpecialCharacters]="false"
                    autocomplete="off"
                    thousandSeparator=","
                    type="text"
                    matInput
                    formControlName="initialPayment"
            />
        </mat-form-field>

        <mat-form-field>
            <mat-label>Payment Currency</mat-label>
            <mat-select formControlName="paymentCurrencyId">
                @for (paymentCurrency of paymentCurrencies() ?? []; track $index) {
                    <mat-option [value]="paymentCurrency.id">{{ paymentCurrency.currency }}
                        ({{ paymentCurrency.network }})
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <div class="flex gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Post Blocking Fee</mat-label>
                <input [mask]="form.controls.isBlockingPercent.value ? 'percent.4' : 'separator.' + decimals()"
                       [dropSpecialCharacters]="false"
                       autocomplete="off"
                       thousandSeparator=","
                       type="text"
                       matInput
                       formControlName="postBlockingFee"/>
            </mat-form-field>
            <mat-checkbox color="primary" formControlName="isBlockingPercent" class="mt-2"
                          (change)="form.controls.postBlockingFee.reset()"><span class="dark:text-white">Percent</span>
            </mat-checkbox>
        </div>

        <div class="flex gap-2">
            <mat-form-field class="flex-1">
                <mat-label>Post Recover Fee</mat-label>
                <input [mask]="form.controls.isRecoveryPercent.value ? 'percent.4' : 'separator.' + decimals()"
                       [dropSpecialCharacters]="false"
                       autocomplete="off"
                       thousandSeparator=","
                       type="text"
                       matInput
                       formControlName="postRecoveryFee"/>
            </mat-form-field>
            <mat-checkbox color="primary" formControlName="isRecoveryPercent" class="mt-2"
                          (change)="form.controls.postRecoveryFee.reset()"><span class="dark:text-white">Percent</span>
            </mat-checkbox>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close color="warn" cdkFocusInitial>Close</button>
    <button mat-flat-button (click)="beforeSubmit()" color="accent" [disabled]="form.invalid">Create</button>
</mat-dialog-actions>
