import { inject, Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ProfileActions, ProfileStoreState } from "../store/profile";

// TODO: Rewrite to new syntax
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _profileStore = inject<Store<{ profile: ProfileStoreState }>>(Store);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._profileStore.dispatch(ProfileActions.logout());
        }
        return throwError(() => error);
      }),
    );
  }
}
