<h2 mat-dialog-title>Upgrade</h2>
<mat-dialog-content [formGroup]="form">
    <div class="flex gap-2">
        <mat-form-field class="flex-1">
            <mat-label>Type</mat-label>
            <mat-select [formControl]="form.controls.packagesId.controls[1]">
                @for (item of offerPackages(); track $index) {
                    <mat-option [value]="item.id">{{ item.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <button mat-icon-button
                (click)="addOfferPackage(); $event.stopPropagation()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent" cdkFocusInitial (click)="beforeSubmit()">Upgrade</button>
</mat-dialog-actions>
