import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";

export type Theme = "light" | "dark";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  get isDev(): boolean {
    return environment.devMode;
  }

  theme: Theme = JSON.parse(localStorage.getItem("theme") ?? '"light"');

  onChangeTheme$: Subject<Theme> = new Subject<Theme>();

  constructor() {
    this.init();
  }

  init(): void {
    if (this.isDev) {
      const { body, documentElement } = document;
      [body, documentElement].forEach((element: HTMLElement) => {
        element.classList.remove("dark", "light");
        element.classList.add(this.theme);
      });
    } else {
      document.body.classList.add("light");
      document.documentElement.classList.add("light");
    }
  }

  toggle(): void {
    this.theme = this.theme == "light" ? "dark" : "light";

    localStorage.setItem("theme", JSON.stringify(this.theme));

    const { body, documentElement } = document;
    [body, documentElement].forEach((element: HTMLElement) => {
      element.classList.remove("dark", "light");
      element.classList.add(this.theme);
    });

    this.onChangeTheme$.next(this.theme);
  }
}
