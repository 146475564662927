import { inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, map, Observable, of, startWith } from "rxjs";
import {
  CaseIncidentResponse,
  CreateInvoiceRequest,
  CreateOfferPackageRequest,
  CreateOfferRequest,
  EvidenceInfo,
  IFile,
  FilterIncidents,
  IdResponse,
  Incident,
  IncidentCaseQueryParams,
  IncidentReport,
  IncidentStatusRequest,
  IncidentSummaryResponse,
  Invoice,
  Offer,
  OfferPackage,
  OfferPackages,
  OfferResponse,
  PaymentCurrencies,
  TableSetting,
  TableSettingRequest,
  TransactionInfo,
  UpdateUserInfoRequest,
  User,
  QueryParamsOfferPackagesList,
  QueryParamsOfferPackages,
} from "../models/api.model";
import { emptyPagination, Pagination } from "../models/pagination.model";
import { Upload, upload } from "../../partials/upload/upload";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly _apiUrl: string = environment.apiUrl;
  private readonly _httpClient: HttpClient = inject(HttpClient);

  // incident-admin-controller
  getIncidents(
    queryParams: Partial<FilterIncidents> = {},
  ): Observable<Incident[]> {
    const params = new HttpParams({ fromObject: { ...queryParams } });
    return this._httpClient.get<Incident[]>(
      `${this._apiUrl}api/v1/admin/incidents`,
      { params },
    );
  }

  getIncidentSummary(incidentId: string): Observable<IncidentSummaryResponse> {
    return this._httpClient.get<IncidentSummaryResponse>(
      `${this._apiUrl}api/v1/admin/incidents/${incidentId}/summary`,
    );
  }

  getIncidentCases(
    queryParams: IncidentCaseQueryParams = {
      page: 0,
      size: 25,
    },
  ): Observable<Pagination<CaseIncidentResponse>> {
    const params = new HttpParams({ fromObject: { ...queryParams } });
    return this._httpClient.get<Pagination<CaseIncidentResponse>>(
      `${this._apiUrl}api/v1/admin/incidents/cases`,
      { params },
    );
  }

  putIncidentStatus(
    incidentId: string,
    body: IncidentStatusRequest,
  ): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/admin/incidents/${incidentId}/status`,
      body,
    );
  }

  // incident-controller
  getIncidentById(id: string): Observable<Incident> {
    return this._httpClient.get<Incident>(
      `${this._apiUrl}api/v1/incidents/${id}`,
    );
  }

  // evidence-info-controller
  getEvidence(incidentId: string): Observable<EvidenceInfo> {
    return this._httpClient.get<EvidenceInfo>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/evidence`,
    );
  }

  // transaction-info-controller
  getTransactions(incidentId: string): Observable<TransactionInfo[]> {
    return this._httpClient.get<TransactionInfo[]>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/transactions`,
    );
  }

  // file-controller
  getFile(id: string): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}api/v1/files/admin/${id}`, {
      responseType: "blob",
    });
  }

  download({
    id,
    filename,
    type,
  }: {
    id: string;
    filename: string;
    type?: string;
  }): Observable<boolean> {
    return this.getFile(id).pipe(
      startWith(true),
      map((data) => {
        if (typeof data !== "boolean") {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(data);
          downloadLink.setAttribute("download", filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          return false;
        }
        return true;
      }),
      catchError((err) => {
        console.log(err);
        return of(false);
      }),
    );
  }

  // offer-package-controller
  getOfferPackage(id: string): Observable<OfferPackage> {
    return this._httpClient.get<OfferPackage>(
      `${this._apiUrl}api/v1/offer-packages/${id}`,
    );
  }

  putOfferPackage(id: string, body: OfferPackage): Observable<OfferPackage> {
    return this._httpClient.put<OfferPackage>(
      `${this._apiUrl}api/v1/offer-packages/${id}`,
      body,
    );
  }

  deleteOfferPackage(id: string): Observable<void> {
    return this._httpClient.delete<void>(
      `${this._apiUrl}api/v1/offer-packages/${id}`,
    );
  }

  getOfferPackages(
    queryParams: Partial<QueryParamsOfferPackages>,
  ): Observable<OfferPackages> {
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this._httpClient.get<OfferPackages>(
      `${this._apiUrl}api/v1/offer-packages`,
      { params },
    );
  }

  getOfferPackagesList(
    queryParams: QueryParamsOfferPackagesList,
  ): Observable<Pagination<OfferPackage>> {
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this._httpClient
      .get<
        Pagination<OfferPackage>
      >(`${this._apiUrl}api/v1/offer-packages/list`, { params })
      .pipe(catchError(() => of(emptyPagination)));
  }

  postOfferPackages(body: CreateOfferPackageRequest): Observable<IdResponse> {
    return this._httpClient.post<IdResponse>(
      `${this._apiUrl}api/v1/offer-packages`,
      body,
    );
  }

  // scam-type-controller
  getPaymentCurrencies(): Observable<PaymentCurrencies> {
    return this._httpClient.get<PaymentCurrencies>(
      `${this._apiUrl}api/v1/payment/currencies`,
    );
  }

  // offer-controller
  postOffer(incidentId: string, body: CreateOfferRequest): Observable<Offer> {
    return this._httpClient.post<Offer>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers`,
      body,
    );
  }

  putUpgradeOffer(
    incidentId: string,
    offerId: string,
    body: { packagesId: string[] },
  ): Observable<Offer> {
    return this._httpClient.put<Offer>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/packages/upgrade`,
      body,
    );
  }

  putOfferCancel(incidentId: string, offerId: string): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/cancel`,
      {},
    );
  }

  getOfferByIncidentIdAndOfferId(
    incidentId: string,
    offerId: string,
  ): Observable<OfferResponse> {
    return this._httpClient.get<OfferResponse>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}`,
    );
  }

  // user-controller
  putUserKYC(userId: string, body: string): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/users/admin/${userId}/kyc`,
      body,
    );
  }

  getUserById(id: string): Observable<User> {
    return this._httpClient.get<User>(`${this._apiUrl}api/v1/users/${id}`);
  }

  putCanCreateReferrals(
    userId: string,
    canCreateReferrals: boolean,
  ): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/users/admin/${userId}/referrals`,
      { canCreateReferrals },
    );
  }

  putUserInfo(
    userId: string,
    body: Partial<UpdateUserInfoRequest>,
  ): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/users/${userId}/info`,
      body,
    );
  }

  // invoice-controller
  postInvoices(
    incidentId: string,
    offerId: string,
    body: CreateInvoiceRequest,
  ): Observable<Invoice> {
    return this._httpClient.post<Invoice>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/invoices`,
      body,
    );
  }

  getInvoicesByIncidentIdAndOfferIdAndInvoiceId(
    incidentId: string,
    offerId: string,
    invoiceId: string,
  ): Observable<Invoice> {
    return this._httpClient.get<Invoice>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/invoices/${invoiceId}`,
    );
  }

  getInvoicesByIncidentIdAndOfferIdAndPackageId(
    incidentId: string,
    offerId: string,
    packageId: string,
  ): Observable<Invoice[]> {
    return this._httpClient.get<Invoice[]>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/packages/${packageId}/invoices`,
    );
  }

  getInvoicesByIncidentId(incidentId: string): Observable<Invoice[]> {
    return this._httpClient.get<Invoice[]>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/invoices`,
    );
  }

  cancelInvoice(
    incidentId: string,
    offerId: string,
    invoiceId: string,
  ): Observable<void> {
    return this._httpClient.put<void>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/offers/${offerId}/invoices/${invoiceId}/cancel`,
      {},
    );
  }

  // incident-report-controller
  getIncidentReports(incidentId: string): Observable<IncidentReport[]> {
    return this._httpClient.get<IncidentReport[]>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/reports`,
    );
  }

  postIncidentReport(
    incidentId: string,
    name: string,
    fileType: string,
    file: File,
  ): Observable<Upload<IdResponse>> {
    const params = new HttpParams({ fromObject: { name, fileType } });
    const body = new FormData();
    body.append("file", file);
    return this._httpClient
      .post<IdResponse>(
        `${this._apiUrl}api/v1/incidents/${incidentId}/reports`,
        body,
        {
          params,
          reportProgress: true,
          observe: "events",
        },
      )
      .pipe(upload<IdResponse>());
  }

  getIncidentReport(
    incidentId: string,
    reportId: string,
  ): Observable<IncidentReport> {
    return this._httpClient.get<IncidentReport>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/reports/${reportId}`,
    );
  }

  deleteIncidentReport(incidentId: string, reportId: string): Observable<void> {
    return this._httpClient.delete<void>(
      `${this._apiUrl}api/v1/incidents/${incidentId}/reports/${reportId}`,
    );
  }

  // Table operations
  getTableSettingsByUserIdAndTableName<T>(
    userId: string,
    tableName: string,
  ): Observable<TableSetting<T>> {
    return this._httpClient
      .get<TableSetting>(
        `${this._apiUrl}api/table-settings/${userId}/${tableName}`,
      )
      .pipe(
        map((v) => ({
          ...v,
          data: JSON.parse(v.data),
        })),
      );
  }

  createOrUpdateTableSetting<T>(
    body: TableSettingRequest,
  ): Observable<TableSetting<T>> {
    return this._httpClient
      .post<TableSetting>(`${this._apiUrl}api/table-settings`, body)
      .pipe(
        map((v) => ({
          ...v,
          data: JSON.parse(v.data),
        })),
      );
  }
}
