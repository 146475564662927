import {
  Component,
  effect,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatButton, MatIconButton } from "@angular/material/button";
import { ApiService } from "../../../../api/services/api.service";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import {
  IdResponse,
  Offer,
  OfferPackage,
  OfferPackages,
  OfferResponse,
} from "../../../../api/models/api.model";
import { delay, retry, switchMap, take, tap } from "rxjs";
import { OfferPackageComponent } from "../offer-package/offer-package.component";
import { MatIcon } from "@angular/material/icon";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-offer-upgrade",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButton,
    FormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatIcon,
    MatIconButton,
  ],
  templateUrl: "./offer-upgrade.component.html",
  styleUrl: "./offer-upgrade.component.scss",
})
export class OfferUpgradeComponent {
  private readonly _matDialogRef: MatDialogRef<OfferUpgradeComponent> = inject(
    MatDialogRef<OfferUpgradeComponent>,
  );

  private readonly _apiService: ApiService = inject(ApiService);

  private readonly _data: { offer: OfferResponse } = inject<{
    offer: OfferResponse;
  }>(MAT_DIALOG_DATA);

  private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

  private readonly _matDialog: MatDialog = inject(MatDialog);

  offerPackages: WritableSignal<OfferPackages> = signal<OfferPackages>([]);

  form = this._fb.group({
    packagesId: this._fb.array([
      this._fb.control<string>(
        this._data.offer.packages?.[0]?.id,
        Validators.required,
      ),
      this._fb.control<string>("", Validators.required),
    ]),
  });

  constructor() {
    this._loadOfferPackages();
  }

  beforeSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.submit();
  }

  submit(): void {
    const {
      offer: { incidentId, id },
    } = this._data;

    const body = this.form.getRawValue();

    this._apiService
      .putUpgradeOffer(incidentId, id, body)
      .pipe(retry(3), take(1))
      .subscribe({
        next: (response: Offer) => {
          this._matDialogRef.close(response);
        },
      });
  }

  addOfferPackage(): void {
    this._matDialog
      .open(OfferPackageComponent, {
        data: { isEssential: false, type: "RECOVER" },
        autoFocus: true,
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(({ id }: IdResponse) =>
          this._apiService.getOfferPackage(id).pipe(
            retry(3),
            take(1),
            tap((offerPackage: OfferPackage) =>
              this.offerPackages.set([offerPackage]),
            ),
          ),
        ),
        delay(50),
        take(1),
      )
      .subscribe({
        next: (offerPackage: OfferPackage) => {
          this.form.controls.packagesId.controls[1].patchValue(offerPackage.id);
        },
      });
  }

  private _loadOfferPackages(): void {
    this._apiService
      .getOfferPackages({ isEssential: true, isDeleted: false })
      .pipe(retry(3), take(1))
      .subscribe({
        next: (offerPackages: OfferPackage[]) => {
          const {
            offer: { packages },
          } = this._data;
          const [_, recoverPackage] = packages;
          this.offerPackages.set(
            offerPackages.filter(
              ({ type, id }) => type === "RECOVER" && id !== recoverPackage.id,
            ),
          );
        },
      });
  }
}
