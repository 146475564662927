import { Routes } from "@angular/router";
import {
  getIncidentReports,
  getIncidentsCases,
  getIncidentSummary,
  getOfferPackagesList,
  getTableSettings,
} from "./resolvers";
import {
  requestTableName,
  RequestTableSettings,
} from "./pages/requests/requests.component";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("./pages/auth/auth.module"),
  },
  {
    path: "",
    loadComponent: () => import("./layout/layout.component"),
    children: [
      {
        path: "requests",
        resolve: {
          initCaseIncidentResponse: getIncidentsCases,
          loadedTableSettings:
            getTableSettings<RequestTableSettings>(requestTableName),
        },
        loadComponent: () => import("./pages/requests/requests.component"),
      },
      {
        path: "case/:incidentId",
        resolve: {
          initialIncidentSummary: getIncidentSummary,
          initialIncidentReports: getIncidentReports,
        },
        loadComponent: () => import("./pages/case/case.component"),
      },
      {
        path: "packages",
        resolve: {
          initialActiveOfferPackages: getOfferPackagesList({
            isEssential: true,
            isDeleted: false,
          }),
          initialDeletedOfferPackages: getOfferPackagesList({
            isEssential: true,
            isDeleted: true,
          }),
        },
        loadComponent: () => import("./pages/packages/packages.component"),
      },
      {
        path: "404",
        loadComponent: () =>
          import("./partials/pages/not-found/not-found.component"),
      },
      {
        path: "something-went-wrong",
        loadComponent: () =>
          import(
            "./partials/pages/something-went-wrong/something-went-wrong.component"
          ),
      },
      {
        path: "",
        pathMatch: "full",
        loadComponent: () =>
          import("./partials/pages/welcome/welcome.component"),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
  },
];
