import { HttpInterceptorFn } from "@angular/common/http";
import { LoaderService } from "../services/loader.service";
import { inject } from "@angular/core";
import { finalize } from "rxjs";

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService: LoaderService = inject(LoaderService);
  loaderService.start();
  return next(req).pipe(finalize(() => loaderService.stop()));
};
