import { inject, Injectable } from "@angular/core";
import { FormControl, NonNullableFormBuilder } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);
  public readonly searchControl: FormControl<string> = this._fb.control("");
  private _router: Router = inject(Router);

  constructor() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: () => {
          this.searchControl.patchValue("", { emitEvent: false });
        },
      });
  }
}
