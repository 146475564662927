import { Component, input, InputSignal } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatRipple } from "@angular/material/core";
import { CopyToClipboardCopiedDirective } from "../../directives/copy-to-clipboard-copied.directive";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-copy-button",
  standalone: true,
  imports: [MatIcon, MatRipple, CopyToClipboardCopiedDirective, NgClass],
  templateUrl: "./copy-button.component.html",
  styleUrl: "./copy-button.component.scss",
})
export class CopyButtonComponent {
  value: InputSignal<string> = input.required();
  size: InputSignal<"small" | "medium" | "big"> = input<
    "small" | "medium" | "big"
  >("big");
}
