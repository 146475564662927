import { createSelector } from "@ngrx/store";
import { ProfileStoreState } from "./profile.state";

export namespace ProfileSelectors {
  export const selectProfile = (state: { profile: ProfileStoreState }) =>
    state.profile;
  export const selectAuthResponse = createSelector(
    selectProfile,
    ({ authResponse }: ProfileStoreState) => authResponse,
  );
  export const selectAuthError = createSelector(
    selectProfile,
    ({ authError }: ProfileStoreState) => authError,
  );
  export const selectProfileInformation = createSelector(
    selectProfile,
    ({ profile }: ProfileStoreState) => profile,
  );
}
