import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { routes } from "./app.routes";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { ProfileStoreModule } from "./core/store/profile";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { errorInterceptor } from "./core/interceptors/error.interceptor";
import { dialogOptions, snackBarOptions } from "./mat-options";
import { loaderInterceptor } from "./partials/interceptors/loader.interceptor";
import { appConfigInit } from "./app-config-init";
import { NgxMaskConfig, provideEnvironmentNgxMask } from "ngx-mask";

const maskConfig: Partial<NgxMaskConfig> = {
  validation: false,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    appConfigInit,
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom([
      MatTooltipModule,
      MatIconModule,
      MatListModule,
      MatSidenavModule,
      StoreModule.forRoot(
        {},
        {
          runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
          },
        },
      ),
      EffectsModule.forRoot([]),
      ProfileStoreModule,
    ]),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(maskConfig),
    provideHttpClient(
      withInterceptors([loaderInterceptor, errorInterceptor]),
      withInterceptorsFromDi(),
    ),
    provideAnimationsAsync(),
    snackBarOptions,
    dialogOptions,
  ],
};
