export interface Profile {
  uuid: string;
  email: string;
  isPassed: boolean;
  phone: string;
  token: string;
  username: string;
  role: "SUPER_USER" | "USER";
}

export interface AuthStepResponse {
  code_length: number;
  code_lifetime: number;
  key: string;
  qrImage: string | null;
  next: AuthSteps;
  mask: string;
}

export enum TypeVerifiedRegister {
  phone = "phone",
  email = "email",
  two_fa_code = "two_fa_code",
}

export enum AuthSteps {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  PHONE_CODE = "PHONE_CODE",
  EMAIL_CODE = "EMAIL_CODE",
  TWO_FA_SETUP = "TWO_FA_SETUP",
  TWO_FA_CODE = "TWO_FA_CODE",
  LOGIN = "LOGIN",
}
