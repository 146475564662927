import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cropWithDotsCenter",
  standalone: true,
})
export class CropWithDotsCenterPipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    start = 6,
    end = 6,
  ): string | null | undefined {
    if (!value) {
      return value;
    }
    if (value.length < start + end) {
      return value;
    }
    return `${value.slice(0, start)}...${value.slice(value.length - end)}`;
  }
}
