<h2 mat-dialog-title>Add Invoice</h2>
<mat-dialog-content>
    <form class="flex flex-col" [formGroup]="form">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input formControlName="expectedAmount"
                   matInput
                   [mask]="'separator.' + 2"
                   [dropSpecialCharacters]="false"
                   autocomplete="off"
                   thousandSeparator=","
                   type="text"/>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
    <button mat-flat-button mat-dialog-close [color]="'warn'" cdkFocusInitial>Close</button>
    <button mat-flat-button [color]="'accent'" (click)="beforeSubmit()" [disabled]="form.invalid">Add</button>
</mat-dialog-actions>
