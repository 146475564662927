import { Component, inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInput } from "@angular/material/input";
import { ApiService } from "../../../../api/services/api.service";
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Incident, Invoice } from "../../../../api/models/api.model";
import { retry, take } from "rxjs";
import { NgxMaskDirective } from "ngx-mask";

@Component({
  selector: "app-add-invoice",
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInput,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  templateUrl: "./add-invoice.component.html",
  styleUrl: "./add-invoice.component.scss",
})
export class AddInvoiceComponent {
  private readonly _apiService: ApiService = inject(ApiService);

  private readonly _matDialogRef: MatDialogRef<AddInvoiceComponent> = inject(
    MatDialogRef<AddInvoiceComponent>,
  );

  private readonly _data: { incident: Incident } = inject<{
    incident: Incident;
  }>(MAT_DIALOG_DATA);

  private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

  form = this._fb.group({
    name: this._fb.control("", [Validators.required]),
    expectedAmount: this._fb.control("", [Validators.required]),
  });

  beforeSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }

    this.submit();
  }

  submit(): void {
    const {
      incident: { id, actualOfferId },
    } = this._data;
    this._apiService
      .postInvoices(id, actualOfferId, this.form.getRawValue())
      .pipe(retry(3), take(1))
      .subscribe({
        next: (response: Invoice) => {
          this._matDialogRef.close(response);
        },
      });
  }
}
