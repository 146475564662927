import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ApiService } from "./api/services/api.service";
import { inject } from "@angular/core";
import { catchError, iif, of, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import { ProfileSelectors, ProfileStoreState } from "./core/store/profile";
import { QueryParamsOfferPackagesList } from "./api/models/api.model";

const handlerResolverError = (router: Router) => async (error: any) => {
  await router.navigateByUrl("/something-went-wrong");
  throw error;
};

export const getIncidentSummary = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
) => {
  const apiService: ApiService = inject(ApiService);
  const { incidentId } = activatedRouteSnapshot.params;
  return apiService.getIncidentSummary(incidentId);
};
export const getInvoicesByIncidentId = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
) => {
  const apiService: ApiService = inject(ApiService);
  const { incidentId } = activatedRouteSnapshot.params;
  return apiService.getInvoicesByIncidentId(incidentId);
};

export const getIncidentReports = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
) => {
  const apiService: ApiService = inject(ApiService);
  const { incidentId } = activatedRouteSnapshot.params;
  return apiService.getIncidentReports(incidentId);
};

export const getIncidentsCases = () => {
  const apiService: ApiService = inject(ApiService);
  return apiService.getIncidentCases();
};

export const getOfferPackagesList =
  (queryParams: Partial<QueryParamsOfferPackagesList>) => () => {
    const apiService: ApiService = inject(ApiService);
    return apiService.getOfferPackagesList(queryParams);
  };

export const getTableSettings =
  <T>(tableName: string) =>
  () => {
    const apiService = inject(ApiService);
    const profileStore: Store<{ profile: ProfileStoreState }> =
      inject<Store<{ profile: ProfileStoreState }>>(Store);
    return profileStore
      .select(ProfileSelectors.selectProfile)
      .pipe(
        switchMap(({ profile }) =>
          iif(
            () => Boolean(profile?.uuid),
            apiService
              .getTableSettingsByUserIdAndTableName<T>(
                profile?.uuid as string,
                tableName,
              )
              .pipe(catchError(() => of(undefined))),
            of(undefined),
          ),
        ),
      );
  };
