import { CdkCopyToClipboard, Clipboard } from "@angular/cdk/clipboard";
import { Directive, HostListener, inject, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Directive({
  selector: "[appCopyToClipboardCopied]",
  standalone: true,
  providers: [CdkCopyToClipboard],
})
export class CopyToClipboardCopiedDirective {
  private clipboard = inject(Clipboard);
  private matSnackBar = inject(MatSnackBar);

  @Input("appCopyToClipboardCopied") value!: string;

  @HostListener("click", ["$event"]) handlerClick(event: PointerEvent): void {
    event.stopPropagation();
    const result = this.clipboard.copy(this.value);
    this.matSnackBar.open(result ? "Was copied!" : "Was`t copied", "Close", {
      duration: 1000,
    });
  }
}
