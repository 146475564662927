import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { of } from "rxjs";

import { Actions, createEffect, ofType } from "@ngrx/effects";

import { ProfileActions } from "./profile.actions";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ProfileStoreEffects {
  private apiUrl: string;

  constructor(
    private _actions$: Actions,
    private _http: HttpClient,
    private _router: Router,
  ) {
    this.apiUrl = environment.apiUrl;
  }

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ProfileActions.logout),
        tap(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          this._router.navigate(["/login"]);
        }),
      ),
    { dispatch: false },
  );

  loginSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProfileActions.loginSuccess),
      tap((action) => {
        localStorage.removeItem("authStep");
        localStorage.setItem("userInfo", JSON.stringify(action.payload));
        localStorage.setItem("token", action.payload.token);
        this._router.navigate(["/requests"]);
      }),
      map((action) => ProfileActions.loadSuccess({ profile: action.payload })),
    ),
  );

  loginProcess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProfileActions.loginProcess),
      mergeMap((action) => {
        return this._http
          .post(`${this.apiUrl}api/v1/admin/login/process`, action.payload)
          .pipe(
            map((response: any) =>
              ProfileActions.loginProcessSuccess({ payload: response }),
            ),
            catchError((error) =>
              of(ProfileActions.loginProcessFail({ error })),
            ),
          );
      }),
    ),
  );

  login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProfileActions.login),
      mergeMap((action) => {
        return this._http
          .post(`${this.apiUrl}api/v1/admin/login`, { key: action.key })
          .pipe(
            map((response: any) =>
              ProfileActions.loginSuccess({ payload: response }),
            ),
            catchError((error) => of(ProfileActions.loginFail({ error }))),
          );
      }),
    ),
  );
}
