import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs"; // TODO: Rewrite to new syntax

// TODO: Rewrite to new syntax
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem("token");
    if (token) {
      request = request.clone({
        setHeaders: { "x-auth-session": token },
      });
    }

    return next.handle(request);
  }
}
