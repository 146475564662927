@let username = telegramUsername();
@if (username) {
    <a target="_blank" [href]="'https://t.me/' + username"
       class="text-blue-300">
        {{ '@' + username }}
    </a>
    <app-copy-button [value]="username" size="small"></app-copy-button>
} @else {
    {{ '---' }}
}
