import {
  Component,
  inject,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { ApiService } from "../../../../api/services/api.service";
import { retry, startWith, take } from "rxjs";
import {
  CreateOfferPackageRequest,
  OfferPackage,
  PaymentCurrencies,
  PaymentCurrency,
} from "../../../../api/models/api.model";
import { MatOption, MatSelect } from "@angular/material/select";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { NgxMaskDirective } from "ngx-mask";
import { MatCheckbox } from "@angular/material/checkbox";

@Component({
  selector: "app-offer-package",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    NgxMaskDirective,
    MatCheckbox,
  ],
  templateUrl: "./offer-package.component.html",
  styleUrl: "./offer-package.component.scss",
})
export class OfferPackageComponent implements OnInit {
  private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

  private readonly _apiService: ApiService = inject(ApiService);

  private readonly _data = inject<{
    offerPackage?: OfferPackage;
    isEssential: boolean;
    type?: "START" | "RECOVER";
  }>(MAT_DIALOG_DATA);

  public readonly paymentCurrencies: Signal<PaymentCurrencies | undefined> =
    toSignal(this._apiService.getPaymentCurrencies().pipe(retry(3)));

  public readonly decimals: WritableSignal<number> = signal(6);

  private readonly _matDialogRef: MatDialogRef<OfferPackageComponent> = inject(
    MatDialogRef<OfferPackageComponent>,
  );

  public readonly form = this._fb.group({
    name: this._fb.control("", Validators.required),
    type: this._fb.control<"START" | "RECOVER">("START", Validators.required),
    isEssential: this._fb.control(false),
    initialPayment: this._fb.control("", Validators.required),
    isBlockingPercent: this._fb.control(false, Validators.required),
    postBlockingFee: this._fb.control(""),
    isRecoveryPercent: this._fb.control(false, Validators.required),
    postRecoveryFee: this._fb.control(""),
    paymentCurrencyId: this._fb.control("", Validators.required),
  });

  constructor() {
    const {
      controls: { type, paymentCurrencyId },
    } = this.form;

    // Обновление decimals
    paymentCurrencyId.valueChanges.pipe(takeUntilDestroyed()).subscribe({
      next: (id) => {
        const paymentCurrencies: PaymentCurrencies | undefined =
          this.paymentCurrencies();

        if (paymentCurrencies) {
          const paymentCurrency: PaymentCurrency | undefined =
            paymentCurrencies.find((i) => i.id === id);
          if (paymentCurrency) {
            const { decimals } = paymentCurrency;
            this.decimals.set(decimals);
          }
        }
      },
    });

    // Обновление состояний формы в зависимости от выбранного типа пакета
    type.valueChanges
      .pipe(startWith(type.value), takeUntilDestroyed())
      .subscribe({
        next: (type: "START" | "RECOVER") => {
          const { controls } = this.form;
          switch (type) {
            case "START": {
              controls.isBlockingPercent.reset({
                value: false,
                disabled: true,
              });

              controls.postBlockingFee.reset({ value: "", disabled: true });
              controls.postBlockingFee.clearValidators();

              controls.isRecoveryPercent.reset({
                value: false,
                disabled: true,
              });

              controls.postRecoveryFee.reset({ value: "", disabled: true });
              controls.postRecoveryFee.clearValidators();
              break;
            }
            case "RECOVER": {
              controls.isBlockingPercent.enable();
              controls.postBlockingFee.enable();
              controls.postBlockingFee.addValidators([Validators.required]);
              controls.isRecoveryPercent.enable();
              controls.postRecoveryFee.enable();
              controls.postRecoveryFee.addValidators([Validators.required]);
              break;
            }
          }
        },
      });
  }

  ngOnInit(): void {
    const { isEssential, type, offerPackage } = this._data;

    this.form.patchValue({
      isEssential,
    });

    // Если передан type в _data, то мы блокируем изменение типа пакета
    if (type) {
      this.form.patchValue({
        type,
      });

      const { controls } = this.form;
      controls.type.disable();

      if (type === "START") {
        controls.isBlockingPercent.disable();
        controls.postBlockingFee.disable();
        controls.isRecoveryPercent.disable();
        controls.postRecoveryFee.disable();
      }
    }

    if (offerPackage) {
      const {
        name,
        postBlockingFee,
        postRecoveryFee,
        paymentCurrency: { id: paymentCurrencyId },
      } = offerPackage;

      this.form.patchValue({
        name,
        postBlockingFee,
        postRecoveryFee,
        paymentCurrencyId,
      });
    }
  }

  public beforeSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }

    this.submit();
  }

  public submit(): void {
    const body: CreateOfferPackageRequest = this.form.getRawValue();
    this._apiService
      .postOfferPackages(body)
      .pipe(retry(3), take(1))
      .subscribe({
        next: (response) => {
          this._matDialogRef.close(response);
        },
      });
  }
}
