import { Component, input, InputSignal } from "@angular/core";
import { CopyButtonComponent } from "../copy-button/copy-button.component";

@Component({
  selector: "app-telegram-user-link",
  standalone: true,
  imports: [CopyButtonComponent],
  templateUrl: "./telegram-user-link.component.html",
  styleUrl: "./telegram-user-link.component.scss",
})
export class TelegramUserLinkComponent {
  telegramUsername: InputSignal<string> = input.required();
}
