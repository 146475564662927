import {
  Component,
  computed,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import {
  MatNativeDateTimeModule,
  MatTimepickerModule,
  provideNativeDateTimeAdapter,
} from "@dhutaryan/ngx-mat-timepicker";
import { MatIcon } from "@angular/material/icon";
import { retry, switchMap, take } from "rxjs";
import { OfferPackageComponent } from "../offer-package/offer-package.component";
import { filter } from "rxjs/operators";
import {
  CreateOfferRequest,
  IdResponse,
  Incident,
  Offer,
  OfferPackage,
  OfferPackages,
  RecoveryProbability,
} from "../../../../api/models/api.model";
import { ApiService } from "../../../../api/services/api.service";
import {
  AbstractControl,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-add-offer",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTimepickerModule,
    MatNativeDateTimeModule,
    MatIcon,
    ReactiveFormsModule,
    DatePipe,
  ],
  providers: [provideNativeDateTimeAdapter()],
  templateUrl: "./add-offer.component.html",
  styleUrl: "./add-offer.component.scss",
})
export class AddOfferComponent {
  private readonly _matDialog: MatDialog = inject(MatDialog);

  private readonly _apiService: ApiService = inject(ApiService);

  private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

  private readonly _matDialogRef: MatDialogRef<AddOfferComponent> = inject(
    MatDialogRef<AddOfferComponent>,
  );

  private readonly _data: { incident: Incident } = inject<{
    incident: Incident;
  }>(MAT_DIALOG_DATA);

  offerPackages: WritableSignal<OfferPackages> = signal<OfferPackages>([]);

  recoveryProbabilityItems: {
    value: RecoveryProbability;
    name: string;
  }[] = [
    {
      value: "EXTREMELY_LOW",
      name: "EXTREMELY LOW",
    },
    {
      value: "LOW",
      name: "LOW",
    },
    {
      value: "MEDIUM",
      name: "MEDIUM",
    },
    {
      value: "HIGH",
      name: "HIGH",
    },
    {
      value: "EXTREMELY_HIGH",
      name: "EXTREMELY_HIGH",
    },
  ];

  minExpiredAt = new Date();

  form = this._fb.group({
    packagesId: this._fb.array(
      [
        this._fb.control("", [Validators.required]),
        this._fb.control("", [Validators.required]),
      ],
      [Validators.required],
    ),
    recoveryProbability: this._fb.control<RecoveryProbability>(
      "EXTREMELY_LOW",
      [Validators.required],
    ),
    expiredAt: this._fb.control<Date>(this.minExpiredAt, [Validators.required]),
    description: this._fb.control("", [Validators.required]),
  });

  formExpiredAt = this._fb.group({
    days: this._fb.control<number>(0, Validators.min(0)),
    hours: this._fb.control<number>(0, Validators.min(0)),
    minutes: this._fb.control<number>(0, Validators.min(0)),
  });

  packagesId = toSignal(this.form.controls.packagesId.valueChanges);

  offerPackageItems1 = computed(() => {
    const offerPackages = this.offerPackages();
    return offerPackages.filter(({ type }) => type === "START");
  });

  offerPackageItems2 = computed(() => {
    const offerPackages = this.offerPackages();
    return offerPackages.filter(({ type }) => type === "RECOVER");
  });

  package1 = computed(() => {
    const [packageId] = this.packagesId() ?? [];
    const offerPackages = this.offerPackages();
    return offerPackages.find((i) => i.id === packageId);
  });

  package2 = computed(() => {
    const [_, packageId] = this.packagesId() ?? [];
    const offerPackages = this.offerPackages();
    return offerPackages.find((i) => i.id === packageId);
  });

  constructor() {
    this._loadOfferPackages();

    this.formExpiredAt.valueChanges.pipe(takeUntilDestroyed()).subscribe({
      next: ({ days, hours, minutes }) => {
        const expiredAt = new Date();

        expiredAt.setDate(expiredAt.getDate() + (days ?? 0));
        expiredAt.setHours(expiredAt.getHours() + (hours ?? 0));
        expiredAt.setMinutes(expiredAt.getMinutes() + (minutes ?? 0));

        this.form.patchValue({
          expiredAt,
        });
      },
    });
  }

  addOfferPackage(control: AbstractControl, type: "START" | "RECOVER"): void {
    this._matDialog
      .open(OfferPackageComponent, {
        data: { isEssential: false, type },
        autoFocus: true,
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(({ id }: IdResponse) => this._apiService.getOfferPackage(id)),
        take(1),
      )
      .subscribe({
        next: (offerPackage: OfferPackage) => {
          this.offerPackages.update((v) => [...v, offerPackage]);
          control.patchValue(offerPackage.id);
        },
      });
  }

  beforeSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.submit();
  }

  submit(): void {
    const formValue = this.form.getRawValue();
    const body: CreateOfferRequest = {
      ...formValue,
      expiredAt: formValue.expiredAt.valueOf(),
    };
    this._apiService
      .postOffer(this._data.incident.id, body)
      .pipe(take(1))
      .subscribe({
        next: (offer: Offer) => {
          this._matDialogRef.close(offer);
        },
      });
  }

  private _loadOfferPackages(): void {
    this._apiService
      .getOfferPackages({ isEssential: true, isDeleted: false })
      .pipe(retry(3), take(1))
      .subscribe({
        next: (offerPackages: OfferPackage[]) =>
          this.offerPackages.set(offerPackages),
      });
  }
}
