interface PageableObject {
  paged: boolean;
  pageNumber: number;
  pageSize: number;
  offset: number;
  sort: SortObject;
  unpaged: boolean;
}

interface SortObject {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Pagination<T> {
  totalPages: number;
  totalElements: number;
  pageable: PageableObject;
  first: boolean;
  last: boolean;
  size: number;
  content: T[];
  number: number;
  sort: SortObject;
  numberOfElements: number;
  empty: boolean;
}

export const emptyPagination = {
  content: [],
  empty: false,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  pageable: {
    paged: false,
    pageNumber: 0,
    pageSize: 0,
    offset: 0,
    sort: {
      sorted: false,
      unsorted: false,
      empty: false,
    },
    unpaged: false,
  },
  size: 0,
  sort: {
    sorted: false,
    unsorted: false,
    empty: false,
  },
  totalElements: 0,
  totalPages: 0,
};
