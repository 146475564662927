<button mat-ripple class="rounded p-1 overflow-hidden" [ngClass]="{
    'min-w-8': size() === 'big',
    'min-w-7': size() === 'medium',
    'min-w-6': size() === 'small',
}"
        [appCopyToClipboardCopied]="value()">
    <mat-icon class="cursor-pointer" [ngClass]="{
        '!w-[14px] !h-[14px] !text-[14px]': size() === 'small',
        '!w-[18px] !h-[18px] !text-[18px]': size() === 'medium'
    }">content_copy
    </mat-icon>
</button>
